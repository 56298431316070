import React from "react";
import styled from "styled-components";

export const IframePdfView = ({ IframePdf }) => {
  return (
    <Container>
      <iframe
        id={IframePdf.id}
        title={IframePdf.title}
        src={IframePdf.src}
      ></iframe>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 3em 0;
  iframe {
    width: 100%;
    height: 100vh;
    border: none;
  }
`;
