import React from "react";
import styled from "styled-components";
import { Department } from "./Department";
import { mediaQuery } from "../../../styles/constants/mediaQuery";

export const Departments = ({
  titleLargeDepartment,
  departments,
  margin = "5rem 0 1rem 0",
}) => (
  <Container id="departments" $margin={margin}>
    <h2>
      {titleLargeDepartment ? titleLargeDepartment : "NUESTROS DEPARTAMENTOS"}
    </h2>
    <hr />
    <WrapperDepartments>
      {departments.map((department, index) => (
        <Department
          key={index}
          titleLarge={department.titleLarge}
          banner={department.banner}
          title={department.title}
          externalUrl={department?.externalUrl}
          url={department?.url}
        />
      ))}
    </WrapperDepartments>
  </Container>
);

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: 1rem;
  position: relative;
  max-width: 1250px;
  margin: auto;

  h2 {
    color: #fff;
    margin: ${({ $margin }) => $margin};
    text-align: center;
    text-transform: uppercase;
    font-size: 2em;
    ${mediaQuery.minTablet} {
      font-size: 3em;
    }
  }

  hr {
    max-width: 23rem;
    width: auto;
    height: 1px;
    margin: 1.2rem auto 5rem auto;
    border: none;
    background: #b3b6b7;
  }
`;

const WrapperDepartments = styled.div`
  display: grid;
  justify-content: center;
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
  grid-gap: 1.5rem;
`;
