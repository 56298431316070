import React from 'react';
import styled from 'styled-components';
import {
	AboutUs,
	Carousel,
	Contact,
	Departments,
	SectionSocial,
	BannerApp
} from '../../components/public';
import { departments } from '../../data-list';

export const Home = () => (
	<Container>
		<Carousel />
		<AboutUs />
		<BannerApp />
		<Departments departments={departments} />
		<Contact />
		<SectionSocial />
	</Container>
);

const Container = styled.div`
	width: 100%;
	height: auto;
`;
