import React from "react";
import styled from "styled-components";
import { IframePdfView } from "./IframePdfView";
import { pdfList } from "../../data-list";
import { useParams } from "react-router";
import { WrapperComponent } from "../../components/public";
import { mediaQuery } from "../../styles/constants/mediaQuery";

export const PdfView = () => {
  const { pdfId } = useParams();
  const IframePdf = pdfList.find((item) => item.id === pdfId);

  return (
    <Container>
      <WrapperComponent>
        <div className="content">
          <WrapperButtom className="container-button">
            <a href={IframePdf.drive} target="_blank">
              Ver Publicación
            </a>
          </WrapperButtom>
          <IframePdfView IframePdf={IframePdf} />
          <WrapperButtom className="container-button">
            <a href={IframePdf.drive} target="_blank">
              Ver Publicación
            </a>
          </WrapperButtom>
        </div>
      </WrapperComponent>
    </Container>
  );
};

const Container = styled.section`
  width: 100%;
  min-height: 100vh;
  padding: 10em 0;

  .content {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const WrapperButtom = styled.div`
  padding: 1em 0;
  a {
    background-color: #fff;
    padding: 0.8em 2em;
    border-radius: 3em;
    color: #000;
    font-size: 1.2em;
    &:hover {
      background-color: rgba(255, 255, 255, 0.8);
    }
    ${mediaQuery.minTablet} {
      padding: 1em 3em;
      font-size: 1.5em;
    }
  }
`;
