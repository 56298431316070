import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";

export const SoonPage = () => {
	return (
		<Container>
			<div>
				<h2>Estamos trabajando por el bienestar</h2>
				<h2>Proximamente...</h2>

				<br />
				<FontAwesomeIcon
					icon={faSpinner}
					size="4x"
					spinPulse
					color="rgb(127, 158, 71)"
				/>
			</div>
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	height: auto;
	padding-top: 130px;
	div {
		width: 100%;
		height: 60vh;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		h2 {
			text-align: center;
			font-size: 2em;
			font-weight: 700;
		}
	}
`;
