import React, { useEffect } from "react";
import { firebase, firestore } from "../../../firebase";
import { useVersion } from "../../../providers";

export const VisitorCounter = () => {
  const { visitorCounter } = useVersion();

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    const increment = await firebase.firestore.FieldValue.increment(1);
    const settingRef = await firestore.collection("settings").doc("default");
    await settingRef.update({ visitorCounter: increment });
  };

  return (
    <div style={{ marginTop: "10px" }}>
      <h2>CONTADOR DE VISITAS : {visitorCounter}</h2>
    </div>
  );
};
