import React from "react";
import styled from "styled-components";
import { BannerAppImg } from "../../../images";

export const BannerApp = () => {
  return (
    <Container>
      <a
        href="https://play.google.com/store/apps/details?id=com.servitec.cobiene"
        target="_blank"
      >
        <img src={BannerAppImg} alt="Banner de aplicación móvil" />
      </a>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 2rem;
  img {
    width: 100%;
  }
`;
