import { Route, Routes } from "react-router-dom";
import { BaseLayout } from "../components/public";
import {
  FlipBook,
  Home,
  JobOpportunity,
  Login,
  Page404,
  PdfView,
  SoonPage,
  SubDepartment,
} from "../pages";
import { AdminLayout } from "../components/admin";
import { FlipBookPage, FlipBookPages, User, Users } from "../pages/admin";
import { PrivateRoute } from "./PrivateRoute";

export const Router = () => (
  <Routes>
    <Route
      exact
      path="/"
      element={
        <BaseLayout>
          <Home />
        </BaseLayout>
      }
    />
    <Route
      exact
      path="/sub-departments/:departmentId"
      element={
        <BaseLayout>
          <SubDepartment />
        </BaseLayout>
      }
    />
    <Route
      exact
      path="/flip-book"
      element={
        <BaseLayout>
          <FlipBook />
        </BaseLayout>
      }
    />
    <Route
      exact
      path="/job-opportunity"
      element={
        <BaseLayout>
          <JobOpportunity />
        </BaseLayout>
      }
    />

    <Route
      exact
      path="/soon-page"
      element={
        <BaseLayout>
          <SoonPage />
        </BaseLayout>
      }
    />

    <Route
      exact
      path="/pdf-view"
      element={
        <BaseLayout>
          <PdfView />
        </BaseLayout>
      }
    />

    <Route
      exact
      path="/pdf-view/:pdfId"
      element={
        <BaseLayout>
          <PdfView />
        </BaseLayout>
      }
    />

    <Route exact path="/login" element={<Login />} />
    {/********************ADMIN ROUTES****************/}
    <Route
      exact
      path="/admin/users"
      element={
        <PrivateRoute>
          <AdminLayout>
            <Users />
          </AdminLayout>
        </PrivateRoute>
      }
    />
    <Route
      exact
      path="/admin/users/:userId"
      element={
        <PrivateRoute>
          <AdminLayout>
            <User />
          </AdminLayout>
        </PrivateRoute>
      }
    />
    <Route
      exact
      path="/admin"
      element={
        <PrivateRoute>
          <AdminLayout>
            <FlipBookPages />
          </AdminLayout>
        </PrivateRoute>
      }
    />
    <Route
      exact
      path="/admin/flip-book-pages"
      element={
        <PrivateRoute>
          <AdminLayout>
            <FlipBookPages />
          </AdminLayout>
        </PrivateRoute>
      }
    />
    <Route
      exact
      path="/admin/flip-book-pages/:flipBookPageId"
      element={
        <PrivateRoute>
          <AdminLayout>
            <FlipBookPage />
          </AdminLayout>
        </PrivateRoute>
      }
    />
    <Route
      exact
      path="*"
      element={
        <BaseLayout>
          <Page404 />
        </BaseLayout>
      }
    />
  </Routes>
);
