import React from "react";
import styled, { css } from "styled-components";
import { mediaQuery } from "../../../styles/constants/mediaQuery";

export const ButtonSuggestionsComplaints = ({ onClick }) => {
  return (
    <WrapperButton
      bg="#202020"
      className="item-text"
      onClick={() => {
        onClick();
      }}
    >
      <div>Sugerencias</div>
    </WrapperButton>
  );
};

const WrapperButton = styled.div`
  position: fixed;
  left: -4rem;
  bottom: 10rem;
  z-index: 1000;
  transform: rotate(-90deg);
  cursor: pointer;

  ${({ bg }) => css`
    background: ${bg};
    padding: 0.3rem 1.2rem;
    border: none;
    margin-bottom: 0.7rem;
    height: auto;
    width: auto;
    border-bottom-left-radius: 7rem;
    border-bottom-right-radius: 7rem;
    ${mediaQuery.minTablet} {
      padding: 0.3rem 2rem;
    }
    .item-text {
      text-align: center;
      font-size: 1rem;
      color: #fff;
      ${mediaQuery.minTablet} {
        font-size: 1.2rem;
      }
    }
  `}
`;
