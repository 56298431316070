import React from "react";
import HTMLFlipBook from "react-pageflip";
import styled from "styled-components";
/*const imagesPages = [
  Portada,
  Tapa,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
  image14,
  image15,
  image16,
  image17,
  ImgCibertex,
  TapaUltima,
  UltimaCara,
];*/
export const FlipBookComponent = ({ imagesPages = [] }) => (
  <Container>
    <HTMLFlipBook
      width={550}
      height={680}
      autoSize
      className="flip-book-container"
    >
      <div className="demoPage" />
      {imagesPages.map((imagePage, index) => (
        <div className="demoPage" key={index}>
          <img
            src={imagePage.flipBookPageImage.url}
            alt="Page cobiene flip book"
          />
        </div>
      ))}
    </HTMLFlipBook>
  </Container>
);

const Container = styled.div`
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0 0 0;
  background: ${({ theme }) => theme.colors.tertiary};
  .demoPage {
    perspective: 2000px;
    position: absolute;
    img {
      width: 100%;
      height: 42rem;
    }
  }
`;
