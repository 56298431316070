import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FlipBookComponent } from "../../components/public";
import { firestore, querySnapshotToArray } from "../../firebase";
import { spinLoader } from "../../utils/loader";
import { orderBy } from "lodash";

export const FlipBook = () => {
  const [loadingFlipBookPages, setLoadingFlipBookPages] = useState(true);
  const [flipBookPages, setFlipBookPages] = useState([]);

  useEffect(() => {
    (() => fetchFlipBookPages())();
  }, []);

  const fetchFlipBookPages = async () => {
    try {
      await firestore.collection("flip-book-pages").onSnapshot((snapshot) => {
        const flipBookPages_ = querySnapshotToArray(snapshot);
        setFlipBookPages(flipBookPages_);
      });
    } catch (e) {
      console.info("[ErrorGetFlipBookPages]: ", e);
    } finally {
      setLoadingFlipBookPages(false);
    }
  };

  const viewFlipBookPages = (flipBookPages) =>
    orderBy(flipBookPages, "pageNumber", "asc");

  if (loadingFlipBookPages) return spinLoader();

  return (
    <Container>
      <WrapperComponents>
        <FlipBookComponent imagesPages={viewFlipBookPages(flipBookPages)} />
      </WrapperComponents>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;
  padding-top: 1em;
`;

const WrapperComponents = styled.div`
  width: 100%;
  max-width: 1250px;
  height: auto;
  margin: auto;
  padding-top: 130px;
`;
